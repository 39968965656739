import * as React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";

const NotFoundPage = () => {
    return (
        <Layout>
            <Navbar/>

            <div id="formContent" className={'container'}>
                <div className="section-title">
                    <h1>Cardizoo</h1>
                    <p>
                        for a hearty life
                    </p>
                </div>
                <h2 className="active"> Sign In </h2>
                <h2 className="inactive underlineHover">Sign Up </h2>

                <form>
                    <input type="text" id="login" className="fadeIn second" name="login" placeholder="login"/>
                    <input type="text" id="password" className="fadeIn third" name="login"
                           placeholder="password"/>
                    <input type="submit" className="fadeIn fourth" value="Log In"/>
                </form>


            </div>
            <Footer />
        </Layout>
    )
}

export default NotFoundPage
